<template>
    <img src="@/assets/svgs/page-confirmed.svg" alt="" class="mx-auto mb-5" />
    <h2 class="text-center text-18 md:text-[28px] font-poller uppercase mb-5">
        Você já está <br />
        confirmado na lista:<br />
        {{ currentList.title }}!
    </h2>
    <p class="text-center text-16 md:text-20 mb-3 leading-normal font-medium">
        Você já foi convidado e seu nome na <br />
        lista está validado!
    </p>
    <sep-image />
    <p class="text-center text-16 md:text-20 mb-6 leading-normal">
        Quer aumentar suas chances <br />
        de concorrer? Clique no botão abaixo <br />
        e faça sua própria lista.
    </p>
    <button @click="goTo('/u/lists')" class="btn relative w-full max-w-[450px] mb-5">
        <span class="mx-auto pt-1">Quer saber mais?</span>
    </button>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useListStore } from "@/stores/listStore";

const listStore = useListStore();
const router = useRouter();

const currentList = ref(listStore.getCurrentList());

const goTo = (value) => {
    router.push(value);
};

onMounted(async () => {
    //currentList.value = await listStore.getLists();
});
</script>
