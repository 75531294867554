import {defineStore} from "pinia";
import Cookies from "js-cookie";

import {callApi} from "@/utils/http";

const API_URI = import.meta.env.VITE_API_URL;

export const useAuthStore = defineStore("auth", {
    state: () => ({
        user: {
            id              : "",
            name            : "",
            email           : "",
            phone           : "",
            city            : "",
            state           : "",
            birthday        : "",
            authType        : "spotify",
            acceptTerms     : false,
            status          : "",
        },
        isLoggedIn          : false,
        accessToken         : "",
        errorMessage        : "",
    }),

    actions: {
        generateRandomString(length) {
            let text = "";
            const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        },

        async loginWithSpotify() {
            try {
                const { VITE_SPOTIFY_CLIENT_ID, VITE_SPOTIFY_REDIRECT_URI, VITE_SPOTIFY_SCOPE } = import.meta.env;
                const appId = VITE_SPOTIFY_CLIENT_ID;
                const redirectUri = VITE_SPOTIFY_REDIRECT_URI;
                const permissions = VITE_SPOTIFY_SCOPE ?? "user-read-private user-read-email playlist-modify-private";
                const scope = encodeURIComponent(permissions);
                const state = this.generateRandomString(16);
                const query = `client_id=${appId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=token&show_dialog=true&state=${state}`;
                window.location.href = `https://accounts.spotify.com/authorize?${query}`;
            } catch (error) {
                this.handleError("Erro ao iniciar login com Spotify. Por favor, tente novamente mais tarde.", error);
            }
        },

        async authWithSpotify(accessToken, referralCode) {
            try {
                const consentOptIn = localStorage.getItem('consentOptIn') ?? false;
                const state = this.generateRandomString(16);
                let query = `access_token=${accessToken}&state=${state}&consent_given=${consentOptIn}`;
                if (referralCode) {
                    query = `${query}&list=${referralCode}`;
                }
                const response = await callApi(`/auth/spotify?${query}`, 'GET');
                this.setAccessToken(response.access_token);
                this.setSocialAuthType('spotify');
                return this.setUser(response.data);
            } catch (error) {
                this.handleError("Erro ao iniciar login com Spotify. Por favor, tente novamente mais tarde.", error);
            }
        },

        async loginWithDeezer() {
            try {
                const { VITE_DEEZER_CLIENT_ID, VITE_DEEZER_REDIRECT_URI, VITE_DEEZER_SCOPE } = import.meta.env;
                const appId = VITE_DEEZER_CLIENT_ID;
                const redirectUri = VITE_DEEZER_REDIRECT_URI;
                const perms = VITE_DEEZER_SCOPE ?? "basic_access,email,manage_library";
                const query = `app_id=${appId}&redirect_uri=${redirectUri}&perms=${perms}`;
                window.location.href = `https://connect.deezer.com/oauth/auth.php?${query}`;
            } catch (error) {
                this.handleError("Erro ao iniciar login com Deezer. Por favor, tente novamente mais tarde.", error);
            }
        },

        async authWithDeezer(code, referralCode) {
            try {
                const { VITE_DEEZER_CLIENT_ID, VITE_DEEZER_CLIENT_SECRET } = import.meta.env;
                const appId = VITE_DEEZER_CLIENT_ID;
                const appSecret = VITE_DEEZER_CLIENT_SECRET;
                const consentOptIn = localStorage.getItem('consentOptIn') ?? false;
                const state = this.generateRandomString(16);
                let query = `app_id=${appId}&secret=${appSecret}&code=${code}&state=${state}&consent_given=${consentOptIn}`;
                if (referralCode) {
                    query = `${query}&list=${referralCode}`;
                }
                const response = await callApi(`/auth/deezer?${query}`, 'GET');
                this.setAccessToken(response.access_token);
                this.setSocialAuthType('deezer');
                return this.setUser(response.data);
            } catch (error) {
                this.handleError("Erro ao trocar o código de autorização por token de acesso do Deezer. Por favor, tente novamente mais tarde.", error);
            }
        },

        async setUser(userData) {
            this.user = {
                id              : userData.id,
                name            : userData.name,
                phone           : userData.phone,
                email           : userData.email,
                city            : userData.city,
                state           : userData.state,
                birthday        : userData.birthday,
                authType        : userData.authentication_type,
                consentGiven    : userData.consentGiven,
                acceptTerms     : userData.acceptTerms ?? false,
                status          : userData.status,
            };
            this.isLoggedIn = true;
            return this.user;
        },

        setAccessToken(newToken) {
            this.accessToken = newToken;
            this.saveAccessToken();
        },

        setSocialAuthType(social) {
            localStorage.setItem('authType', social);
        },

        getSocialAuthType() {
            return localStorage.getItem('authType');
        },

        async userIsLoggedIn() {
            if (!this.isLoggedIn) {
                this.accessToken = this.getSavedAccessToken();
                if (this.accessToken) {
                    try {
                        const user = await this.getUserInfo();
                    } catch (error) {
                        this.handleError("Erro ao buscar perfil.", error);
                    }
                }
            }
            return this.isLoggedIn;
        },

        async getUserInfo() {
            try {
                const response = await callApi("/users");
                const userData = response?.data;
                const AccessToken = response?.access_token;
                if (AccessToken) {
                    this.setAccessToken(AccessToken);
                }
                if (userData) {
                    return await this.setUser(userData);
                }
            } catch (error) {
                this.handleError("Erro ao buscar perfil.", error);
            }
        },

        async saveProfile() {
            try {
                const data = this.user;
                // console.log(this.user);
                const response = await callApi("/users", "PUT", data);
                this.setUser(response.data);
                return response;
            } catch (error) {
                throw error;
            }
        },

        async saveGuestProfile(referralCode) {
            try {
                const data = this.user;
                if (referralCode) {
                    this.user.invitedList = referralCode;
                }
                const response = await callApi(`/lists/${referralCode}/invites`, 'PUT', data);
                this.setUser(response.data.user);
                return response;
            } catch (error) {
                throw error;
            }
        },

        async registerInvite(referralCode) {
            try {
                return await callApi(`/lists/${referralCode}/invites`, 'POST');
            } catch (error) {
                throw error;
            }
        },

        isAuthenticated() {
            return this.isLoggedIn;
        },

        getUserStatus() {
            return this.user?.status;
        },

        saveAccessToken() {
            return Cookies.set("__cktkvx", this.accessToken, { secure: true, sameSite: "strict", expires: 1 });
        },

        getSavedAccessToken() {
            try {
                return Cookies.get("__cktkvx");
            } catch (error) {
                this.handleError("Erro ao obter cookie:", error);
            }
        },

        removeSavedAccessToken() {
            try {
                Cookies.remove("__cktkvx");
            } catch (error) {
                this.handleError("Erro ao remover cookie:", error);
            }
        },

        async runOnAppStart() {
            return await this.userIsLoggedIn();
        },

        async logout() {
            if (this.isLoggedIn) {
                await callApi(`/logout`);
                this.removeSavedAccessToken();
                this.isLoggedIn = false;
            }
        },

        handleError(message, error) {
            console.error("handleError", message, error);
            this.errorMessage = message;
            throw new Error(message);
        },

    },

});
