<template>
    <div class="progress">
        <div class="progress__inner" :style="{ width: progressBarWidth + '%' }"></div>
    </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
    max: {
        type: Number,
        required: true,
    },
    count: {
        type: Number,
        required: true,
    },
});

const progressBarWidth = computed(() => {
    return (props.count / props.max) * 100;
});
</script>

<style scoped>
.progress {
    @apply relative h-[14px] bg-[#A15934] overflow-hidden rounded-lg;
    box-shadow: 0px 12px 20px 0px rgb(71 8 8 / 50%);
}
.progress__inner {
    @apply absolute top-0 h-full;
    background: url(@/assets/images/bg-progress-bar.png) repeat-x;
}
</style>
