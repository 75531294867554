<template>
    <img src="@/assets/svgs/page-list.svg" alt="" class="mx-auto mb-5" />

    <h2 class="text-center text-18 md:text-[28px] font-poller uppercase">
        Você está confirmado na <br />
        lista XXX
    </h2>

    <p class="text-center text-16 md:text-20 mb-6 leading-normal">
        Você só pode <br />
        participar de 1 (uma) <br />
        lista criada pela <br />
        mesma pessoa.
    </p>

    <sep-image />

    <p class="text-center text-16 md:text-20 mb-6 leading-normal">
        Quer aumentar suas chances <br />
        de concorrer? Clique no botão abaixo <br />
        e faça sua própria lista.
    </p>

    <button @click="goTo('/u/lists')" class="btn relative w-full max-w-[450px] mb-5">
        <span class="mx-auto pt-1">Crie sua lista</span>
    </button>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const goTo = (value) => {
    router.push(value);
};
</script>
