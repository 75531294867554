<template>
    <div class="flex flex-col justify-center items-center text-center">
        <h1 class="text-[26px] md:text-[32px] font-poller uppercase">
            O resultado sairá<br />
            no dia 05/06.
        </h1>
        <sep-image />
        <p class="max-w-[300px] md:max-w-[480px] text-18 md:text-[20px] text-center mb-6">
            Enquanto o resultado não sai, faça um aquecimento com a playlist abaixo!
            <br />
            Sua participação será validada após a confirmação de {{ $totalInvites }} amigos na lista.
        </p>
        <iframe
            v-if="authType === 'deezer'"
            class="mx-auto"
            title="deezer-widget"
            src="https://widget.deezer.com/widget/dark/playlist/6516023264"
            width="320"
            height="400"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media; clipboard-write"
        ></iframe>
        <iframe
            v-else
            class="mx-auto"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/playlist/2nEfCxE92XkBSVwHtJNl0d?utm_source=generator&theme=0"
            width="320"
            height="400"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
        ></iframe>
        <p class="max-w-[300px] md:max-w-[480px] text-18 md:text-[20px] text-center my-6">
            <strong>Mas por que parar por aqui?</strong><br />
            Aumente suas chances criando mais listas de convidados.
        </p>

        <button @click="goTo('/u/lists')" class="btn relative w-full max-w-[340px] min-w-[200px] mb-5">
            <vue-feather type="plus" class="w-6 h-6 absolute left-5 top-1/2 -translate-y-1/2" />
            <span class="mx-auto pt-1"> Criar listas </span>
        </button>

        <menu-app />
    </div>
</template>

<script setup>

    import { ref, onMounted } from 'vue';
    import { useRouter } from "vue-router";
    import { useAuthStore } from '@/stores/authStore';
    const router = useRouter();

    import MenuApp from "@/components/MenuApp.vue";

    const authStore = useAuthStore();
    const authType  = ref('');

    onMounted(async () => {
        authType.value = authStore.getSocialAuthType();
    });

    const goTo = (url) => {
        router.push(url);
    };
</script>
