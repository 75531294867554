<template>
  <div class="card max-w-[360px] md:max-w-[590px] min-h-[350px] mx-auto flex flex-col justify-center items-center"/>
  <Loading v-if="isLoading" />
</template>

<script setup>

  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/stores/authStore';
  import { useListStore } from '@/stores/listStore';
  import { useUIStore } from "@/stores/uiStore";

  import Loading from "@/components/Loading.vue";

  const router      = useRouter();
  const authStore   = useAuthStore();
  const listStore   = useListStore();
  const uiStore     = useUIStore();

  const { addLoadingItem, removeLoadingItem, addToastMessages } = uiStore;

  onMounted(async () => {

    addLoadingItem('Autenticando ...');

    const hash = window.location.hash.substr(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');

    if (accessToken) {

      try {

        const referralCode = await listStore.getReferralCodeByLocalStorage();

        // console.log('auth', referralCode);

        const user = await authStore.authWithSpotify(accessToken);

        removeLoadingItem();

        if (referralCode) {

          await listStore.clearReferralCode();

          if (['social'].includes(user.status)) {

            // console.log('SpotifyCallback', 'social', 'goTo: /i/form');
            await router.push(`/i/${referralCode}/register`);

          } else if (['guest', 'registered'].includes(user.status)) {

            // console.log('SpotifyCallback', 'guest/registered / referralCode', `goTo: /i/${referralCode}/confirm`);
            await router.push(`/i/${referralCode}/register`);

          } else {

            // console.log('SpotifyCallback', 'referralCode', 'goTo: /i/init');
            await router.push(`/i/${referralCode}`);

          }

        } else {

          if (['guest', 'social'].includes(user.status)) {

            // console.log('SpotifyCallback.vue', 'social', 'goTo: /u/register');
            await router.push('/u/register');

          } else if (['registered'].includes(user.status))  {

            // console.log('SpotifyCallback', 'registered', 'goTo: /u/lists');
            await router.push('/u/lists');

          } else {

            await router.push(`/`);

          }

        }

      } catch (error) {

        //console.error('SpotifyCallback.vue', 'Erro ao obter informações do usuário:', error);
        removeLoadingItem();
        addToastMessages('Não foi possível estabelecer conexão com o Spotify.', 'fail');
        await router.push('/');

      }

    } else {

      // console.error('SpotifyCallback.vue', 'Token de acesso não encontrado.');
      removeLoadingItem();
      addToastMessages('Não foi possível estabelecer conexão com o Spotify.', 'fail');
      await router.push('/');

    }

  });

</script>
