<template>

    <div class="inline-flex rounded-md shadow-sm mb-6" role="group">
        <RouterLink to="/u/lists" class="px-3 py-3 font-poller text-12 uppercase text-white bg-transparent border border-white rounded-s-full hover:bg-brown hover:text-white">
            Minhas listas
        </RouterLink>
        <RouterLink to="/u/invites" class="px-3 py-3 font-poller text-12 uppercase text-brown bg-white border border-white rounded-e-full hover:bg-brown hover:text-white">
            Meus convites
        </RouterLink>
    </div>

    <transition name="fade">
        <div class="flex flex-col justify-center items-center">
            <h1 class="text-[26px] md:text-[32px] font-poller uppercase">Meus convites!</h1>
            <sep-image />

            <p class="max-w-[300px] md:max-w-[480px] text-18 md:text-[20px] text-center mb-6" v-if="invites.length === 0">Você ainda não está participando de nenhuma lista.</p>
            <p class="max-w-[300px] md:max-w-[480px] text-18 md:text-[20px] text-center mb-6" v-else>Veja abaixo as listas em que você está participando:</p>

            <img class="max-w-[360px] md:max-w-full block mb-10 md:mb-10" v-show="invitesReceived && invites.length === 0" src="@/assets/images/no-list.png" alt="Nenhuma lista encontrada" />

            <transition name="fade" v-if="invitesReceived">
                <div v-show="invitesReceived">
                    <div class="flex flex-col items-center">
                        <InviteItem v-for="invite in invites" :key="invite.id" :invite="invite" @list-edit="editList" @list-delete="deleteList" @open-copy-code-modal="openCopyCodeModal" @phrase-submit="openFormPhrase" class="w-full max-w-md" />
                    </div>
                    <p class="font-bolw text-18 text-white text-center mt-[32px]">
                        Confira a Playlist que<br />preparamos para você!
                    </p>
                    <div class="p-5">
                        <RouterLink class="btn is-outline hover:border-light-green hover:text-light-green relative w-full min-w-[250px]" @click="" to="/u/playlist">
                            <vue-feather type="music" class="w-6 h-6 absolute left-5" />
                            <span class="mx-auto pt-1"> Ouça agora</span>
                        </RouterLink>
                    </div>
                </div>
            </transition>

            <div v-else class="flex justify-center items-center w-full h-full min-h-[250px]">
                <div class="loader"></div>
            </div>

            <transition name="fade">
                <Modal v-if="showModalLink" @close="showModalLink = false">
                    <FormCopyCode :inviteId="inviteId" @close="showModalLink = false" />
                </Modal>
            </transition>

        </div>

    </transition>

    <term-link />

    <menu-app />

</template>

<script setup>
    import { computed, onMounted, ref, provide, watchEffect } from "vue";
    import { useAuthStore } from "@/stores/authStore";
    import { useListStore } from "@/stores/listStore";
    import { useUIStore } from "@/stores/uiStore";

    import RemovalConfirmationDialog from "@/components/RemovalConfirmationDialog.vue";
    import FrameBorder from "@/components/FrameBorder.vue";
    import Modal from "@/components/Modal.vue";
    import InviteItem from "@/components/InviteItem.vue";
    import FormCopyCode from "@/components/list/FormCopyCode.vue";
    import MenuApp from "@/components/MenuApp.vue";

    const { getInvites, getUserInvites } = useListStore();
    const { addLoadingItem, removeLoadingItem, addToastMessages } = useUIStore();

    const inviteId               = ref("");
    const authType              = ref("");
    const inviteType            = ref("");

    const showModalLink         = ref(false);

    const invites = ref([]);
    const invitesReceived = ref(false);

    watchEffect(async () => {
        invites.value = await getInvites()
    });

    onMounted(async () => {
        await getUserInvites();
        invitesReceived.value = true;
    });

    // actions to lists
    const buttonText = computed(() => (lists.value.length > 0 ? "Criar mais listas" : "Criar"));

    const openCopyCodeModal = (id) => {
        inviteId.value = id;
        showModalLink.value = true;
    };

    const openFormCopyCode = (id = null) => {
        inviteId.value = id;
        showModalLink.value = true;
    };

    // confirmation
    const isDialogVisible = ref(false);

    const confirmDeletion = async () => {
        if (inviteId.value) {
            addLoadingItem();
            try {
                await deleteList(inviteId.value);
            } catch (error) {
                addToastMessages(error.response.data.message, "fail");
                removeLoadingItem();
                return;
            }
            removeLoadingItem();
            addToastMessages("Removido com sucesso", "success");
            isDialogVisible.value = false;
        }
    };

    const cancelDeletion = () => {
        inviteId.value = null;
        isDialogVisible.value = false;
    };

    const showRemovalDialog = (id) => {
        inviteId.value = id;
        isDialogVisible.value = true;
    };

    provide("showRemovalDialog", showRemovalDialog);

</script>

<style scoped>
    .loader {
        border: 6px solid #f3f3f3;
        @apply border-t-light-green;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 0.7s ease-in-out infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
