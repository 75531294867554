<template>

    <transition name="fade">
        <div v-if="inviteStatus === 'register'" class="block-invite">
            <FormRegister :status-invite="inviteStatus" @update:status="updateInviteStatus" />
        </div>
    </transition>

    <transition name="fade">
        <div v-if="inviteStatus === 'success'" class="block-invite">
            <Success />
        </div>
    </transition>

    <transition name="fade">
        <div v-if="inviteStatus === 'listFull'" class="block-invite">
            <ListFull />
        </div>
    </transition>

    <transition name="fade">
        <div v-if="inviteStatus === 'listCreator'" class="block-invite">
            <ListCreator />
        </div>
    </transition>

    <transition name="fade">
        <div v-if="inviteStatus === 'alreadyInCreatorList'" class="block-invite">
            <AlreadyInCreatorList />
        </div>
    </transition>

    <menu-app v-if="userStatus === 'register'" />

</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/authStore";
import { useListStore } from "@/stores/listStore";
import { useUIStore } from "@/stores/uiStore";

import FormRegister from "@/views/invite/pages/FormRegister.vue";
import ListCreator from "@/views/invite/pages/ListCreator.vue";
import ListFull from "@/views/invite/pages/ListFull.vue";
import Success from "@/views/invite/pages/Success.vue";
import AlreadyInCreatorList from "@/views/invite/pages/AlreadyInCreatorList.vue";
import MenuApp from "@/components/MenuApp.vue";

const router = useRouter();
const authStore = useAuthStore();
const listStore = useListStore();
const uiStore = useUIStore();

const inviteStatus = ref("init");

const { addLoadingItem, removeLoadingItem, addToastMessages } = uiStore;

const userStatus = ref(authStore.user.status);

// console.log("userStatus", userStatus.value);

onMounted(async () => {
    if (userStatus.value === "social") {
        inviteStatus.value = "register";
    } else {
        addLoadingItem();

        const referralCode = await listStore.getReferralCode();

        // console.log("InviteRegister", referralCode, "addInvite");

        await authStore
            .registerInvite(referralCode)
            .then(async (response) => {
                // console.log("InviteRegister", response.data);
                response = response.data;
                const statusMapping = {
                    200: () => {
                        updateInviteStatus("success");
                    },
                    201: () => {
                        updateInviteStatus("success");
                    },
                    3000: () => {
                        updateInviteStatus("success");
                    },
                    3001: () => {
                        updateInviteStatus("listCreator");
                    },
                    3002: () => {
                        updateInviteStatus("listFull");
                    },
                    3003: () => {
                        updateInviteStatus("alreadyInCreatorList");
                    },
                    default: () => {
                        throw new Error(response.message);
                    },
                };
                const statusCode = response.statusCode || "default";
                const statusFunction = statusMapping[statusCode] || statusMapping["default"];
                statusFunction();
                uiStore.removeLoadingItem();
            })
            .catch(async (error) => {
                uiStore.removeLoadingItem();
            });

        // await authStore.logout();
    }
});

const currentList = ref(listStore.getCurrentList());

const updateInviteStatus = (newStatus) => {
    inviteStatus.value = newStatus;
};
</script>

<style scoped>
.block-invite {
    @apply w-full max-w-[360px] md:max-w-[590px] mx-auto flex flex-col justify-center items-center transition-opacity duration-500;
}
</style>
