<template>
    <transition name="modal">
        <div class="p-5 fixed z-index[50] top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-85 z-50">
            <div class="shadow-[0_25px_50px_-12px_rgba(0,0,0,0.9)] bg-brown p-4 pt-10 w-full max-w-[400px] relative rounded-2xl">
                <vue-feather type="x-circle" @click="closeModal" class="absolute top-3 left-3 cursor-pointer" />
                <slot />
            </div>
        </div>
    </transition>
</template>

<script setup>
import { defineEmits } from "vue";
const emit = defineEmits(["close"]);

const closeModal = () => {
    emit("close");
};
</script>

<style scoped>
.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
    opacity: 0;
}
</style>
