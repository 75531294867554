// stores/listStore.js

import {ref} from "vue";
import {defineStore} from "pinia";
import {useRoute} from "vue-router";

import {callApi} from "@/utils/http";
import {useAuthStore} from "@/stores/authStore";

export const useListStore = defineStore("listStore", key => {

    const INVITE_LIMIT = parseInt(import.meta.env.VITE_LIST_INVITE_LIMIT || 10);
    const MIN_VERIFIED_INVITES = parseInt(import.meta.env.VITE_LIST_MIN_VERIFIED_INVITES || 10);

    const currentRoute = useRoute();
    const authStore = useAuthStore();

    const accessToken = authStore.accessToken;

    const referralCode          = ref(null);
    const currentList           = ref({});
    const lists                 = ref([]);
    const invites               = ref([]);

    function getCurrentList() {
        return currentList.value;
    }

    function getLists() {
        return lists.value;
    }

    async function getListById(listId) {
        const list = lists.value.find((item) => item.id === listId);
        return list || null;
    }

    async function getUserLists() {
        lists.value = [];
        try {
            await callApi("/lists", "GET").then((response) => {
                lists.value.push(...response.data);
            });
        } catch (error) {
            // console.error("Erro ao obter listas:", error.message);
        }
        return lists.value;
    }

    async function createOrUpdateList(list) {

        try {

            const data = { title: list.title, phrase: list.phrase };

            let entity;

            if (list.id === null) {

                entity = await callApi("/lists", "POST", data);
                // console.log('createOrUpdateList', 'post/lists', entity);

            } else {

                if (list.confirm) {
                    data.confirm = true;
                }

                entity = await callApi(`/lists/${list.id}`, "PUT", data);
                // console.log('createOrUpdateList', 'put/lists', entity);

            }

            if (entity) {

                const entityData = entity;
                const existingListIndex = await lists.value.findIndex((existingList) => existingList.id === entityData.id);

                if (existingListIndex !== -1) {
                    Object.assign(lists.value[existingListIndex], entityData);
                } else {
                    lists.value.push({ ...entityData, invites: list.invites || [] });
                }

                return entity;

            }

        } catch (error) {

            // console.error('createOrUpdateList', 'Erro ao salvar a lista. Tente novamente mais tarde.', error);
            throw error;

        }
    }

    async function deleteList(listId) {
        // console.log("listStore.deleteList", "ID:", listId);
        let request;
        try {
            await callApi(`/lists/${listId}`, "DELETE").then(() => {
                request = lists.value = lists.value.filter((item) => item.id !== listId);
            });
        } catch (error) {
            // console.error('listStore.deleteList', 'Erro ao remover a lista. Tente novamente mais tarde.', error);
            this.errorMessage = "Erro ao remover a lista. Tente novamente mais tarde.";
            throw error;
        }
        return request;
    }

    function getInvites() {
        return invites.value;
    }

    async function getUserInvites() {
        invites.value = [];
        try {
            await callApi("/invites", "GET").then((response) => {
                invites.value.push(...response.data);
            });
        } catch (error) {
            // console.error("Erro ao obter listas:", error.message);
        }
        return lists.value;
    }

    async function createInvite(listId, type, data) {
        // console.log("listStore.createInvite", listId, type, data);
        const newInvite = {
            name: data.name,
            type: type,
            ...(type === "email" ? { email: data.emailOrPhone } : { phone: data.emailOrPhone }),
        };
        const existingListIndex = lists.value.findIndex((item) => item.id === listId);
        if (existingListIndex === -1) {
            // console.error("listStore.createInvite", "List not found for id:", listId);
            return { error: "List not found for id: " + listId };
        }
        const inviteExists = lists.value.some((list) => list.invites.some((invite) => (type === "email" ? invite.email === data.emailOrPhone : invite.phone === data.emailOrPhone)));
        if (inviteExists) {
            // console.error("listStore.createInvite", "Invite with this email or phone already exists in one of the lists");
            return { error: "Invite with this email or phone already exists in one of the lists" };
        }
        try {
            const entity = await callApi(`/lists/${listId}/invites`, "POST", newInvite);
            // console.log("listStore.createInvite", entity);
            lists.value[existingListIndex].invites.push(entity.data);
        } catch (error) {
            // console.error("createOrUpdateList", "Erro ao salvar a lista. Tente novamente mais tarde.");
            this.errorMessage = "Erro ao incluir o convidado na lista. Tente novamente mais tarde.";
            throw error;
        }
        // console.log("Create new invite", newInvite, "in list with index", existingListIndex, "Total invites now:", lists.value[existingListIndex].invites.length);
        return { success: true };
    }

    async function deleteInvite(inviteId, list) {
        // console.log("listStore.deleteInvite", "ID:", inviteId);
        try {
            await callApi(`/lists/${list.id}/invites/${inviteId}`, "DELETE").then(() => {
                const inviteIndex = list.invites.findIndex((invite) => invite.id === inviteId);
                if (inviteIndex !== -1) {
                    list.invites.splice(inviteIndex, 1);
                    // console.log("Invite deleted successfully. Updated invites:", list.invites);
                } else {
                    // console.log("Invite not found.");
                }
            });
        } catch (error) {
            // console.error('listStore.deleteList', 'Erro ao remover a lista. Tente novamente mais tarde.', error);
            this.errorMessage = "Erro ao remover a lista. Tente novamente mais tarde.";
            throw error;
        }
    }

    function clearReferralCode() {
        localStorage.removeItem('referralCode');
    }

    function setReferralCode(referralCode) {
        localStorage.setItem('referralCode', referralCode);
        this.referralCode = referralCode;
    }

    function getReferralCode(route) {
        if(!route) {
            route = currentRoute;
        }
        referralCode.value = route && route.params.referralCode ? route.params.referralCode : null;
        // console.log('getReferralCode', referralCodeByRoute, route.params);
        /*
        const referralCodeByLocalStorage = getReferralCodeByLocalStorage();
        if (referralCodeByRoute) {
            setReferralCode(referralCodeByRoute);
            this.referralCode = referralCodeByRoute;
        } else {
            this.referralCode = referralCodeByLocalStorage;
        }
        // console.log('listSore', 'getReferralCode', this.referralCode);
        */
        return referralCode.value;
    }

    function getReferralCodeByLocalStorage() {
        const referralCode = localStorage.getItem('referralCode');
        clearReferralCode();
        // console.log('getReferralCodeByLocalStorage', referralCode);
        return referralCode;
    }

    async function getListByReferralCode(referralCode) {
        try {
            if (!referralCode) {
                referralCode = getReferralCode();
            }
            if (typeof referralCode === 'string') {
                const response = await callApi(`/lists/${referralCode}/invites`, "GET");
                currentList.value = response.data;
            } else {
                currentList.value = null;
            }
        } catch (error) {
            // console.error('Erro ao obter lista por código:', error);
            currentList.value = null;
        }
        return currentList.value;
    }

    async function isInviteLimitReached(listId) {
        const list = lists.value.find((item) => item.id === listId);
        return list && list.invites ? list.invites.length >= INVITE_LIMIT : false;
    }

    async function hasVerifiedInvites(listId) {
        const list = lists.value.find((item) => item.id === listId);
        if (list && list.invites) {
            const verifiedInvitesCount = list.invites.reduce((count, invite) => {
                return invite.isVerified ? count + 1 : count;
            }, 0);
            const hasVerifiedInvites = verifiedInvitesCount >= MIN_VERIFIED_INVITES;
            return list.invites.every((invite) => invite.isVerified) && hasVerifiedInvites;
        }
        return false;
    }

    return {
        getCurrentList,
        getListById,
        getLists,
        createOrUpdateList,
        deleteList,
        getUserInvites,
        getInvites,
        getListByReferralCode,
        getReferralCodeByLocalStorage,
        getUserLists,
        getReferralCode,
        setReferralCode,
        clearReferralCode,
        createInvite,
        deleteInvite,
        isInviteLimitReached,
        hasVerifiedInvites,
    };

});
