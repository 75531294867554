<template>
    <h1 class="text-center text-18 md:text-32 font-poller uppercase mb-3">Bora!!<br /></h1>

    <p class="text-center text-16 md:text-20 mb-6 uppercase">
        Confirme sua presença preenchendo <br />
        os campos abaixo
    </p>

    <div class="card w-full min-[380px]">
        <form @submit.prevent="saveProfile" autocomplete="off">
            <InputField id="displayName" v-model="displayName" label="Nome" icon="user" :error="formErrors?.name" />
            <InputField id="userEmail" v-model="userEmail" label="E-mail" icon="mail" :error="formErrors?.email" :readonly="true" />
            <PhoneField id="userPhone" v-model="userPhone" label="Telefone" icon="phone" :maxlength="15" :error="formErrors?.phone" />

            <div class="flex items-center">
                <input type="checkbox" id="acceptTerms" v-model="acceptTerms" class="h-4 w-4 text-green-600 rounded bg-gray-800 border-gray-600 focus:ring-green-500" />
                <label for="acceptTerms" class="ml-2 block text-sm text-white normal-case">
                    <span class="text-18">Eu concordo com os <a href="/files/regulamento.pdf" class="colo-white underline" target="_blank">termos de uso</a></span>
                </label>
            </div>

            <div class="mb-5">
                <span class="" v-if="error">{{ error[0] }}</span>
                <span class="field-error" v-if="formErrors?.acceptTerms">{{ formErrors.acceptTerms[0] }}</span>
            </div>

            <LoadingButton type="submit" :isLoading="isLoading" :class="{ '!opacity-100': acceptTerms }">
                <span>Avançar</span>
                <vue-feather type="chevrons-right" class="w-6 h-6 inline-block text-orange" />
            </LoadingButton>
        </form>
    </div>
</template>

<script setup>
import { ref, defineEmits, computed } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/authStore";
import { useListStore } from "@/stores/listStore";
import { useUIStore } from "@/stores/uiStore";

import { isValidPhoneNumber } from "@/utils/utils";

import FormRegister from "@/views/invite/pages/FormRegister.vue";

import FloatLabel from "@/components/forms/FloatLabel.vue";
import InputField from "@/components/forms/InputField.vue";
import PhoneField from "@/components/forms/PhoneField.vue";
import LoadingButton from "@/components/forms/LoadingButton.vue";

const emit = defineEmits(["update:status"]);

const router = useRouter();
const authStore = useAuthStore();
const listStore = useListStore();
const uiStore = useUIStore();

const { addLoadingItem, removeLoadingItem, addToastMessages } = uiStore;

const displayName = ref(authStore.user.name);
const userEmail = ref(authStore.user.email);
const userPhone = ref(authStore.user.phone);
const acceptTerms = ref(false);

const isLoading = ref(false);
const formErrors = ref({});

const currentList = ref(listStore.getCurrentList());

const saveProfile = async () => {
    const referralCode = await listStore.getReferralCode();

    if (checkForm() !== true) {
        uiStore.addToastMessages("Informe os campos em destaque!", "fail");
        return;
    }

    addLoadingItem();
    isLoading.value = true;

    authStore.user = {
        name: displayName,
        phone: userPhone,
    };

    await authStore
        .saveGuestProfile(referralCode)
        .then(async (response) => {
            response = response.data;
            const statusMapping = {
                200: () => {
                    updateInviteStatus("success");
                },
                201: () => {
                    updateInviteStatus("success");
                },
                3000: () => {
                    updateInviteStatus("success");
                },
                3001: () => {
                    updateInviteStatus("listCreator");
                },
                3002: () => {
                    updateInviteStatus("listFull");
                },
                3003: () => {
                    updateInviteStatus("alreadyInCreatorList");
                },
                default: () => {
                    throw new Error(response.message);
                },
            };
            const statusCode = response.statusCode || "default";
            const statusFunction = statusMapping[statusCode] || statusMapping["default"];
            statusFunction();
        })
        .catch(async (error) => {
            uiStore.addToastMessages(error, "fail");
        });

    isLoading.value = false;
    uiStore.removeLoadingItem();
};

// validation
const checkForm = () => {
    formErrors.value = {};
    if (!displayName.value || displayName.value.trim().length === 0) formErrors.value.name = ["O campo nome é obrigatório"];
    if (!userEmail.value || userEmail.value.trim().length === 0) formErrors.value.email = ["O campo e-mail é obrigatório"];
    if (!userPhone.value || userPhone.value.trim().length === 0) {
        formErrors.value.phone = ["O campo telefone é obrigatório"];
    } else {
        if (!isValidPhoneNumber(userPhone.value)) formErrors.value.phone = ["O número de telefone é inválido"];
    }
    if (!acceptTerms.value || acceptTerms.value !== true) formErrors.value.acceptTerms = ["Você precisa ler e concordar com os termos de uso"];
    return Object.keys(formErrors.value).length <= 0;
};

const updateInviteStatus = (newStatus) => {
    emit("update:status", newStatus);
};
</script>
