<template>
    <transition name="fade">
        <div class="fixed inset-0 flex items-end justify-center">
            <div class="optin-overlay overflow-auto fixed inset-0 z-50 flex items-end justify-center">
                <div class="w-full md:shadow-md md:p-0">
                    <div class="bg-black w-full p-3 shadow-[0px_-11px_33px_10px_#00000024]">
                        <div class="max-w-[1100px] mx-auto">
                            <h2 class="font-bold mb-2 uppercase text-center mx-auto">Autorização de Dados</h2>
                            <div class="flex">
                                <CheckboxField
                                    class="text-left text-14 mb-6 leading-normal"
                                    v-model="isChecked"
                                    id="checkConsent"
                                    @update:modelValue="handleCheckboxChange"
                                    label="Autorizo a utilização de meus dados pessoais (nome, e-mail e telefone) pela Sony Music para o envio de novidades e campanhas de mídia relacionadas aos projetos e artistas nacionais e internacionais do Cast Sony Music, seja no Brasil ou exterior, via redes sociais, Google, SMS e aplicativos de mensagens. Confirmo ainda que tenho/sou maior de 18 anos. Essa assinatura poderá ser cancelada a qualquer momento."
                                />
                            </div>
                            <button class="btn is-outline min-w-[250px] mx-auto px-4 flex flex-nowrap justify-center items-center gap-2 grow hover:border-2 hover:border-solid hover:border-[#ffc1ad] hover:text-orange hover:bg-white" @click="closePopup" aria-label="Edit">
                                <span class="text-[20px] leading-normal"> Comece aqui </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { defineEmits, ref, computed } from "vue";

import CheckboxField from "@/components/forms/CheckboxField.vue";

const isChecked = ref(false);

const emit = defineEmits("optin:close");

const handleCheckboxChange = (value) => {
    isChecked.value = value;
    // console.log("Optin:handleCheckboxChange", isChecked.value);
};

const closePopup = () => {
    // console.log("Optin:closePopup", isChecked.value);
    emit("optin:close", isChecked.value);
};

const isSmallScreen = computed(() => {
    return window.innerWidth < 720;
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.optin-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

/* Aplica estilos quando a tela é menor que 720px */
@media (max-width: 719px) {
    .overflow-auto {
        overflow: hidden;
    }
}
</style>
