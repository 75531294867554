<template>
    <div class="checkbox-container flex items-start gap-4">
        <input v-model="isChecked" type="checkbox" :id="id" @change="toggleCheckbox" hidden />
        <div class="checkbox min-w-4 min-h-4 border border-white flex justify-center items-center cursor-pointer mt-2" @click="toggleCheckbox">
            <img v-if="isChecked" src="@/assets/svgs/checkbox.svg" alt="" class="opacity-0 transition-opacity duration-300" />
        </div>
        <label class="cursor-pointer user-select-none">{{ label }}</label>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const isChecked = ref(false);

const props = defineProps({
    modelValue: Boolean,
    id: String,
    label: String,
});

const emit = defineEmits("update:modelValue");

const toggleCheckbox = () => {
    isChecked.value = !isChecked.value;
    emit("update:modelValue", isChecked.value);
};
</script>

<style scoped>
.checkbox img {
    opacity: 1;
}
</style>
