<template>
    <img src="@/assets/svgs/page-list-unavailable.svg" alt="" class="mx-auto mb-5" />
    <h2 class="text-center text-18 md:text-[28px] font-poller uppercase mb-5">
        Infelizmente essa <br> lista não está mais <br> disponível!<br />
    </h2>
    <p class="text-center text-16 md:text-20 mb-3 leading-normal font-medium">
        Vamos avisar o(a) dono(a) <br> para criar uma nova.
    </p>
    <sep-image />
    <p class="text-center text-16 md:text-20 mb-6 leading-normal">
        Enquanto isso, que tal criar a <br/>
        sua própria lista? Clique no botão <br/> abaixo para participar.
    </p>
    <button @click="goTo('/u/lists')" class="btn relative w-full max-w-[450px] mb-5">
        <span class="mx-auto pt-1">Crie sua lista</span>
    </button>
</template>

<script setup>
    import { useRouter } from "vue-router";
    const router = useRouter();
    const goTo = (value) => {
        router.push(value);
    };
</script>
