<template>
    <img src="@/assets/svgs/page-list-owner.svg" alt="" class="mx-auto mb-5" />
    <h2 class="text-center text-18 md:text-[28px] font-poller uppercase">Você é o(a) dono(a)<br /> da lista</h2>
    <sep-image />
    <p class="text-center text-16 md:text-20 mb-6 leading-normal">
        Como criador da lista, assim que você<br />
        tiver {{ $totalInvites }} convidados confirmados,<br />
        você estará concorrendo ao churrasco<br />
        com tudo pago
    </p>
    <button @click="goTo('/u/lists')" class="btn relative w-full max-w-[450px] mb-5">
        <span class="mx-auto pt-1">Ir para home</span>
    </button>
</template>

<script setup>
    import { useRouter } from "vue-router";
    const router = useRouter();
    const goTo = (value) => {
        router.push(value);
    };
</script>
