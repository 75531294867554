<template>
    <Wrapper v-cloak>
        <FrameBorder>
            <img src="@/assets/images/logo.png" alt="Logo" class="max-w-[267px] block mx-auto mb-5" />
            <div class="flex flex-col justify-center items-center">
                <router-view />
                <Loading />
                <NotFound v-if="notFound" />
            </div>
        </FrameBorder>
    </Wrapper>
    <Toast />
    <transition name="fade">
        <OptInPopup v-if="showPopup" :isChecked="consentOptIn" @optin:close="closePopup" />
    </transition>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Loading from "@/components/Loading.vue";
import { notFound } from "@/router";

import Toast from "@/components/Toast.vue";
import NotFound from "@/views/NotFound.vue";
import Wrapper from "@/components/Wrapper.vue";
import FrameBorder from "@/components/FrameBorder.vue";
import OptInPopup from "@/components/OptInPopup.vue";

const showPopup = ref(false);
const showPlayList = ref(false);
const consentOptIn = ref(false);

const openPopup = () => {
    if (!localStorage.hasOwnProperty("consentOptIn")) {
        showPopup.value = false; // true para aparecer
    }
};

const closePopup = (value) => {
    consentOptIn.value = value;
    localStorage.setItem("consentOptIn", consentOptIn.value);
    showPopup.value = false;
};

onMounted(() => {
    const checkIfLoaded = () => {
        if (document.readyState === "complete") {
            openPopup();
        } else {
            setTimeout(checkIfLoaded, 1500);
        }
    };
    checkIfLoaded();
});
</script>
