// http.js

import axios from "axios";

import { useAuthStore } from "@/stores/authStore";

const API_URI = import.meta.env.VITE_API_URL;

export async function callApi(endpoint, method = "GET", data = null, options = {}) {

    const authStore = useAuthStore();
    const accessToken = authStore.accessToken;

    try {

        if (accessToken) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${accessToken}`,
            };
        }

        const config = {
            method: method,
            url: `${API_URI}${endpoint}`,
            data: data,
            validateStatus: false,
            ...options,
        };

        const response = await axios(config);

        if (options.raw) {
            return response;
        }
        return response.data;

    } catch (error) {

        if (error.response) {
            throw error;
        }

    }
}
