<template>
    <div v-if="showResult">
        <p class="text-center text-20 leading-normal uppercase font-poller mb-5">Promoção finalizada!</p>
        <h1 class="text-center text-[42px] md:text-[58px] leading-tight font-poller uppercase mb-5">Parabéns,<br />Eduardo S.</h1>
        <p class="text-center text-18 md:text-20 leading-normal">A equipe entrará em contato com você!</p>
    </div>
    <div v-else>
        <h1 class="text-center text-18 md:text-28 leading-9 font-poller uppercase mb-2">CONCORRA A UM <br />CHURRASCÃO COM <br />TUDO PAGO</h1>
        <p class="text-center text-18 mb-6 leading-normal">
            Faça seu login para participar <br />
            e crie sua lista de convidados para o <span class="font-semibold">Churras dos Amigos.</span> <br /><br />

            <span class="font-semibold">Convide {{ $totalInvites }} amigos e participe agora!</span>
        </p>
        <div v-if="!isLoggedIn" class="flex flex-col mx-auto justify-center items-center text-center min-w-[380px]">
            <button @click="login('spotify')" class="btn-social is-spotify mb-3">Logar com Spotify</button>
            <button @click="login('deezer')" class="btn-social is-deezer mb-6">Logar com Deezer</button>
        </div>
        <div v-else>
            <button @click="participate()" class="btn relative w-full max-w-[450px] mb-5">
                <span class="mx-auto pt-1">Participar</span>
            </button>
        </div>
        <term-link />
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useListStore } from "@/stores/listStore";

import { useRouter } from "vue-router";
const router = useRouter();

const authStore = useAuthStore();
const listStore = useListStore();

const isLoggedIn = ref(authStore.isLoggedIn);
const showResult = ref(true);
const user = ref(authStore.user);

const login = (provider) => {
    if (provider === "spotify") {
        // authStore.loginWithSpotify();
    } else if (provider === "deezer") {
        // authStore.loginWithDeezer();
    }
};

const participate = async () => {
    const { status } = authStore.user;
    if (["registered"].includes(status)) {
        await router.push("/u/lists");
    } else {
        await router.push("/u/register");
    }
};
</script>
