<template>
    <div v-if="currentList">
        <h1 class="text-center text-18 md:text-[28px] font-poller uppercase">
            <span class="text-green-400">[ {{ currentList.title }} ]</span> <br />
            <span class="text-green-400" v-if="currentList && currentList.owner">[ {{ currentList.owner.name }} ]<br /></span>
            Você foi convidado<br />
            para um churrasco com tudo pago!
        </h1>
        <sep-image />
        <p class="text-center text-22 mb-6 leading-normal">
            Só falta você se cadastrar para cavar<br /> uma vaguinha nessa resenha!
        </p>

        <div v-if="!isLoggedIn" class="flex flex-col mx-auto justify-center items-center text-center min-w-[380px]">
            <button @click="handleLogin('spotify')" class="btn-social is-spotify mb-3">Aceitar com Spotify</button>
            <button @click="handleLogin('deezer')" class="btn-social is-deezer mb-6">Aceitar com Deezer</button>
        </div>
        <div v-else class="text-center">
            <button @click="participate()" class="btn relative w-full max-w-[450px] mb-5">
                <span class="mx-auto pt-1">Aceitar</span>
            </button>
        </div>
        <term-link />

        <menu-app v-if="user && user.status && user.status === 'register'" />

    </div>
</template>

<script setup>

    import { ref, onMounted } from 'vue';

    import { useRouter } from "vue-router";
    import { useAuthStore } from '@/stores/authStore';
    import { useListStore } from "@/stores/listStore";

    const authStore = useAuthStore();

    const router = useRouter();
    const listStore = useListStore();
    const currentList = ref(null);

    const isLoggedIn = ref(authStore.isLoggedIn);
    const user = ref(authStore.user);

    onMounted(() => {
        currentList.value = listStore.getCurrentList();
    });

    const handleLogin = (provider) => {
        if (provider === 'spotify') {
            // authStore.loginWithSpotify();
        } else if (provider === 'deezer') {
            // authStore.loginWithDeezer();
        }
    };

    const participate = async () => {
        const { status } = authStore.user;
        await router.push(`/i/${currentList.value.id}/register`);
    };

</script>
