<template>
    <div class="flex items-top gap-3">
        <img class="w-[36px] h-[36px]" src="@/assets/svgs/user.svg" />
        <div class="flex items-end font-poller leading-none">
            <span class="text-36 relative bottom-[-2px]">{{ count }}</span>
            <span class="text-22">/</span>
            <span class="text-24">{{ limitInvite }}</span>
        </div>
    </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
    list: Object,
});

const limitInvite = import.meta.env.VITE_LIST_INVITE_LIMIT || 10;

const count = computed(() => (props.list && props.list.invites ? props.list.invites.length : 0));
</script>
