<template>

    <div class="bg-brown rounded-lg shadow-md p-4 mx-auto my-4 w-full min-w-[350px] relative">

        <div v-if="hasMinVerified" class="absolute top-[-18px] left-1/2 transform -translate-x-1/2 flex justify-center items-center h-9 w-9 bg-light-green rounded-full">
            <vue-feather type="check" size="28" class="text-brown" />
        </div>

        <div class="flex flex-row items-center gap-[20px]">
            <div>
                <svg class="w-12 h-12 text-light-green" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15.147 15.085a7.159 7.159 0 0 1-6.189 3.307A6.713 6.713 0 0 1 3.1 15.444c-2.679-4.513.287-8.737.888-9.548A4.373 4.373 0 0 0 5 1.608c1.287.953 6.445 3.218 5.537 10.5 1.5-1.122 2.706-3.01 2.853-6.14 1.433 1.049 3.993 5.395 1.757 9.117Z"
                    />
                </svg>
            </div>

            <div>
                <p class="font-bebas tracking-widest text-light-green text-20 leading-6 pr-4 py-4 truncate max-w-[320px]">{{ invite.roster.title }}</p>
                <p class="font-bebas tracking-widest text-16 leading-6 pr-4 py-1 truncate">Organizador:<br> {{ invite.owner.name }}</p>
                <p class="font-bebas tracking-widest text-16 leading-6 pr-4 py-1 truncate">Aceito em:<br> {{ invite.invitedAt }}</p>
            </div>
        </div>

    </div>

</template>

<script setup>
    // setup
    import { ref, defineProps, defineEmits, onMounted, computed, watch, watchEffect, inject } from "vue";

    // store
    import { useListStore } from "@/stores/listStore";
    import { useUIStore } from "@/stores/uiStore";

    // components
    import { useSingleton } from 'vue-tippy'

    import 'tippy.js/dist/tippy.css'
    import 'tippy.js/themes/light.css'

    const singletons = ref([]);

    useSingleton(singletons, {
        placement: 'top',
        theme: 'dark',
        moveTransition: 'transform 0.2s ease-out',
    });

    const limitInvite = import.meta.env.VITE_LIST_INVITE_LIMIT || 10;

    const { deleteInvite, isInviteLimitReached, hasVerifiedInvites } = useListStore();

    const emit = defineEmits(["list-edit", "list-delete", "open-copy-code-modal", "show-playlist", "phrase-submit"]);

    const props = defineProps({
        invite: Object,
    });

    // data stores
    const uiStore = useUIStore();
    const { addToastMessages } = uiStore;

    const openCopyCodeModal = async () => {
        emit("open-copy-code-modal", props.list.id);
    };

    const toggleAccordion = () => {
        showAccordion.value = !showAccordion.value;
    };

    const handleDeleteInvite = (inviteId) => {
        deleteInvite(inviteId, props.list);
    };

    const handleEdit = () => {
        // console.log("List.vue", "Edit list", props.list.id);
        if (props.list) {
            emit("list-edit", props.list.id);
        }
    };

    const handlePhraseSubmit = () => {
        // console.log("List.vue", "Phrase submit", props.list.id);
        if (props.list) {
            emit("phrase-submit", props.list.id);
        }
    };

    const handleDelete = () => {
        // console.log("List.vue", "Delete list", props.list.id);
        if (props.list) {
            showRemovalDialog(props.list.id);
            //emit('list-delete', props.list.id);
        }
    };

    const toggleShowPlaylist = () => {
        showPlaylist.value = !showPlaylist.value;
        // console.log('ListItem', 'togglePlaylist', showPlaylist.value);
        emit("show-playlist", showPlaylist.value);
    };

    const showRemovalDialog = inject("showRemovalDialog");

</script>

<style>
    .tippy-box {
        @apply bg-white text-orange font-bold uppercase;
    }
    .tippy-box > .tippy-svg-arrow {
        fill: text-brown;
    }
    .tippy-arrow {
        @apply text-white;
    }
</style>
