<template>
    <Modal :class="{ shake: isShaking }" @close="closeForm">
        <form autocomplete="off" class="flex flex-col justify-between h-full pb-5" @submit.prevent="handleSaveList">
            <div class="flex flex-col justify-center">
                <h2 class="text-center text-20 font-poller uppercase my-5">Agora diz aí</h2>
                <p class="text-center text-18 pb-5">Por que você e seus {{ $totalInvites }} amigos merecem ganhar um churrascão com tudo pago?</p>
                <div class="relative">
                    <textarea class="form-control peer" id="listPhrase" cols="30" rows="10" v-model="listPhrase" />
                    <float-label for="listPhrase" :value="listPhrase">Digite aqui</float-label>
                </div>
                <div class="h-[30px]">
                    <span class="field-error" v-if="formErrors?.phrase">{{ formErrors.phrase[0] }}</span>
                </div>
            </div>
            <div v-if="!saving">
                <button type="submit" class="btn w-full">Enviar</button>
            </div>
            <button v-else class="btn w-full">Enviando...</button>
        </form>
    </Modal>
</template>

<script setup>
    // vue
    import { defineProps, defineEmits, ref, watch } from "vue";

    // store
    import { useListStore } from "@/stores/listStore";
    import { useUIStore } from "@/stores/uiStore";

    // components
    import Modal from "@/components/Modal.vue";
    import FloatLabel from "@/components/forms/FloatLabel.vue";
    import InputField from "@/components/forms/InputField.vue";

    // props
    const props = defineProps({
        listId: { type: String, default: "" },
    });

    // events
    const emit = defineEmits([
        "list-save", "close-form"
    ]);

    const PHRASE_CHAR_LIMIT = parseInt(import.meta.env.VITE_LIST_PHRASE_LIMIT || 300);

    const uiStore = useUIStore();
    const { getListById, createOrUpdateList } = useListStore();
    const { addLoadingItem, removeLoadingItem, addToastMessages } = uiStore;

    // data
    const listId        = ref(props.listId);
    const listTitle     = ref("");
    const listPhrase    = ref("");
    const saving        = ref(false);
    const formErrors    = ref([]);
    const isShaking     = ref(false);
    const formIsValid   = ref(false);

    const fetchList = async (id) => {
        // store data
        const list = await getListById(id);
        if (list) {
            listTitle.value =  list.title ?? '';
            listPhrase.value = list.phrase ?? '';
        } else {
            addToastMessages("Não foi possível encontrar a lista.", "fail");
        }
    };

    // watchers
    watch(
        () => props.listId,
        (newValue) => {
            listId.value = newValue;
            if (newValue) {
                fetchList(newValue);
            } else {
                listPhrase.value = "";
            }
        },
        { immediate: true }
    );

    watch(
        () => listPhrase.value,
        (newValue) => {
            if (newValue && newValue.length > PHRASE_CHAR_LIMIT) {
                listPhrase.value = newValue.substring(0, PHRASE_CHAR_LIMIT);
            }
        }
    );

    // methods
    const closeForm = () => emit("close-form");

    const handleSaveList = async () => {
        formErrors.value = [];
        const isValid = await isListPhraseValid();
        if (!isValid) {
            removeLoadingItem();
            return;
        }

        addLoadingItem('Enviando frase...');

        const response = await createOrUpdateList({
            id      : listId.value,
            title   : listTitle.value,
            phrase  : listPhrase.value,
            confirm : true,
        });

        removeLoadingItem();

        if (response.errors) {

            addToastMessages("Não foi possível enviar a frase. Tente novamente.", "fail");

        } else {
            if (formErrors.value.length < 0) return;
            addToastMessages(`A lista ${listTitle.value} já está concorrendo!`, "success");
            closeForm();
        }

    };

    // validation
    const isListPhraseValid = async () => {
        const trimmedPhrase = listPhrase.value.trim();
        if (trimmedPhrase.length === 0) {
            isShaking.value = true;
            await new Promise((resolve) => setTimeout(resolve, 1500));
            isShaking.value = false;
            formIsValid.value = false;
            return false;
        }
        formIsValid.value = true;
        return true;
    };

</script>
