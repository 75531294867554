<template>
    <div class="inline-flex rounded-md shadow-sm mb-6" role="group">
        <RouterLink to="/u/lists" class="px-3 py-3 font-poller text-12 uppercase text-brown bg-white border border-white rounded-s-full hover:bg-brown hover:text-white"> Minhas listas </RouterLink>
        <RouterLink to="/u/invites" class="px-3 py-3 font-poller text-12 uppercase text-white bg-transparent border border-white rounded-e-full hover:bg-brown hover:text-white"> Meus convites </RouterLink>
    </div>

    <transition name="fade">
        <div class="flex flex-col justify-center items-center">
            <h1 class="text-[26px] md:text-[32px] font-poller uppercase text-center" v-if="lists.length > 0">Confira suas listas</h1>
            <h1 class="text-[26px] md:text-[32px] font-poller uppercase text-center" v-else>Bora pra lista!</h1>
            <p class="max-w-[300px] md:max-w-[460px] text-18 md:text-[20px] text-center mb-3" v-if="lists.length > 0">Acompanhe o progresso das suas listas. Lembrando que cada lista só estará concorrendo após a confirmação dos {{ $totalInvites }} amigos convidados.</p>
            <div v-else>
                <p class="max-w-[300px] md:max-w-[460px] text-18 md:text-[20px] text-center mb-3">Crie quantas quiser e convoque seus amigos para concorrer ao churrascão com tudo pago! <br /></p>
                <p class="max-w-[300px] md:max-w-[440px] text-18 md:text-[20px] text-center mb-6">Sua participação será validada após a confirmação de {{ $totalInvites }} amigos na lista.</p>
            </div>
            <img class="max-w-[360px] md:max-w-full block mb-10 md:mb-10" v-show="listsReceived && lists.length === 0" src="@/assets/images/no-list.png" alt="Nenhuma lista encontrada" />
            <transition name="fade" v-if="listsReceived">
                <div v-show="listsReceived" class="mt-6">
                    <div class="flex flex-col items-center">
                        <ListItem v-for="list in lists" :key="list.id" :list="list" @list-edit="editList" @list-delete="deleteList" @open-copy-code-modal="openCopyCodeModal" @phrase-submit="openFormPhrase" class="w-full max-w-md" />
                    </div>
                    <RouterLink v-if="userStatus == 'guest'" to="/u/register" class="btn flex items-center relative w-full max-w-[450px] min-w-[250px] mt-4 mb-5">
                        <vue-feather type="plus" class="w-6 h-6 absolute left-5 top-1/2 -translate-y-1/2" />
                        <span class="mx-auto pt-1"> {{ buttonText }} </span>
                    </RouterLink>
                    <button v-else @click="createList" class="btn relative w-full max-w-[450px] min-w-[250px] mt-4 mb-5">
                        <vue-feather type="plus" class="w-6 h-6 absolute left-5 top-1/2 -translate-y-1/2" />
                        <span class="mx-auto pt-1"> {{ buttonText }} </span>
                    </button>
                    <p class="font-bolw text-18 text-white text-center mt-[32px]">Confira a Playlist que<br />preparamos para você!</p>
                    <div class="p-5">
                        <RouterLink class="btn is-outline hover:border-light-green hover:text-light-green relative w-full" @click="" to="/u/playlist">
                            <vue-feather type="music" class="w-6 h-6 absolute left-5" />
                            <span class="mx-auto pt-1"> Ouça agora</span>
                        </RouterLink>
                    </div>
                </div>
            </transition>
            <div v-else class="flex justify-center items-center w-full h-full min-h-[250px]">
                <div class="loader"></div>
            </div>
            <transition name="fade">
                <Modal v-if="showModalLink" @close="showModalLink = false">
                    <FormCopyCode :listId="listId" @close="showModalLink = false" />
                </Modal>
            </transition>
        </div>
    </transition>

    <term-link />

    <transition name="fade">
        <FormList v-if="showFormList" :listId="listId" @list-edit="editList" @list-save="saveList" @close-form="closeForm" ref="formList" />
    </transition>

    <transition name="fade">
        <FormPhrase v-if="showFormPhrase" :listId="listId" @list-edit="editList" @list-save="saveList" @close-form="closeForm" ref="formPhrase" />
    </transition>

    <transition name="fade">
        <RemovalConfirmationDialog v-if="isDialogVisible" :title="'Confirmar remoção'" :message="'Digite &quot;<strong>deletar</strong>&quot; para confirmar a remoção da lista.'" @confirm="confirmDeletion" @cancel="cancelDeletion" />
    </transition>

    <menu-app />
</template>

<script setup>
import { computed, onMounted, ref, provide, watchEffect } from "vue";
import { useRoute } from "vue-router";

import { useAuthStore } from "@/stores/authStore";
import { useListStore } from "@/stores/listStore";
import { useUIStore } from "@/stores/uiStore";

import RemovalConfirmationDialog from "@/components/RemovalConfirmationDialog.vue";
import FrameBorder from "@/components/FrameBorder.vue";
import Modal from "@/components/Modal.vue";
import ListItem from "@/components/ListItem.vue";
import FormList from "@/components/list/FormList.vue";
import FormPhrase from "@/components/list/FormPhrase.vue";
import FormCopyCode from "@/components/list/FormCopyCode.vue";
import MenuApp from "@/components/MenuApp.vue";

const { getSocialAuthType, user } = useAuthStore();
const { getLists, getUserLists, deleteList } = useListStore();
const { addLoadingItem, removeLoadingItem, addToastMessages } = useUIStore();

const userStatus = ref(user.status);
const route = useRoute();

const listId = ref("");
const authType = ref("");
const inviteType = ref("");

const showFormList = ref(false);
const showFormInvite = ref(false);
const showFormPhrase = ref(false);
const showModalLink = ref(false);

const lists = ref([]);
const listsReceived = ref(false);

watchEffect(async () => {
    lists.value = await getLists();
});

onMounted(async () => {
    await getUserLists();
    listsReceived.value = true;
    authType.value = getSocialAuthType() ?? "spotify";

    if (route.params.open) {
        createList();
    }
});

// actions to lists
const buttonText = computed(() => (lists.value.length > 0 ? "Criar mais listas" : "Criar"));

const openCopyCodeModal = (id) => {
    listId.value = id;
    showModalLink.value = true;
};

const closeForm = () => {
    showFormList.value = false;
    showFormInvite.value = false;
    showFormPhrase.value = false;
};

const createList = () => {
    // console.log("Lists.vue", "createList");
    listId.value = "";
    openFormList();
};

const editList = (id) => {
    // console.log("Lists.vue", "editList", id);
    openFormList(id);
};

const saveList = (list) => {
    // console.log("Lists.vue", "saveList", list);
    showFormList.value = false;
};

const openFormList = (id = null) => {
    // console.log("Lists.vue", "openFormList", id);
    listId.value = id;
    showFormList.value = true;
};

const openFormCopyCode = (id = null) => {
    listId.value = id;
    showModalLink.value = true;
};

provide("callOpenFormList", openFormList);

// actions to invites
const openFormInvite = (id, type = "email") => {
    // console.log("Lists.vue", "openFormInvite", id, type);
    listId.value = id;
    inviteType.value = type;
    showFormInvite.value = true;
};
provide("callOpenFormInvite", openFormInvite);

const openFormPhrase = (id) => {
    // console.log("Lists.vue", "openFormPhrase", id);
    listId.value = id;
    showFormPhrase.value = true;
};
provide("callOpenFormPhrase", openFormPhrase);

// confirmation
const isDialogVisible = ref(false);

const confirmDeletion = async () => {
    if (listId.value) {
        addLoadingItem();
        try {
            await deleteList(listId.value);
        } catch (error) {
            addToastMessages(error.response.data.message, "fail");
            removeLoadingItem();
            return;
        }
        removeLoadingItem();
        addToastMessages("Removido com sucesso", "success");
        isDialogVisible.value = false;
    }
};

const cancelDeletion = () => {
    listId.value = null;
    isDialogVisible.value = false;
};

const showRemovalDialog = (id) => {
    listId.value = id;
    isDialogVisible.value = true;
};

const openWhatsAppWeb = (id) => {
    listId.value = id;
    const APP_URL = import.meta.env.VITE_APP_URL;
    const link = `${APP_URL}/i/${id}`;
    const message = `Olá! Bora pro Churras dos Amigos? Um rolezão com tudo pago! Confirme sua presença aqui ${link} e vamos curtir juntos!`;
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/?text=${encodedMessage}`;
    window.open(url, "_blank");
};

const sendMail = (id) => {
    listId.value = id;
    const APP_URL = import.meta.env.VITE_APP_URL;
    const link = `${APP_URL}/i/${id}`;
    const subject = "Convite para o Churras dos Amigos";
    const message = `Olá! Bora pro Churras dos Amigos? Um rolezão com tudo pago! Confirme sua presença aqui ${link} e vamos curtir juntos!`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
};

provide("showRemovalDialog", showRemovalDialog);
provide("openWhatsAppWeb", openWhatsAppWeb);
provide("sendMail", sendMail);
</script>

<style scoped>
.loader {
    border: 6px solid #f3f3f3;
    @apply border-t-light-green;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.7s ease-in-out infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
