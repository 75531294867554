<template>
    <div class="fixed z-[250] top-5 right-5 w-[50px] h-[50px] bg-white rounded-[50%] flex justify-center items-center cursor-pointer hover:opacity-90 transition" @click="toggle">
        <img class="block pt-3 pb-3" src="@/assets/svgs/menu.svg" alt="" />
    </div>
    <transition name="menu">
        <div v-if="showMenu" class="fixed z-[150] top-0 left-0 w-full h-full bg-white p-5 flex items-center justify-center flex-col">
            <div>
                <vue-feather type="x-circle" @click="toggle" class="absolute top-3 md:top-10 left-3 md:left-10 cursor-pointer text-orange hover:text-brow" />
            </div>
            <ul class="p-4 w-full max-w-[290px] relative rounded-2xl">
                <li class="border-b border-orange">
                    <RouterLink class="text-orange hover:text-brown text-18 text-center font-semibold tracking-widest block p-3 menu-item" @click="toggle" to="/u/lists"> Minhas Listas</RouterLink>
                </li>

                <li class="border-b border-orange">
                    <RouterLink class="text-orange hover:text-brown text-18 text-center font-semibold tracking-widest block p-3 menu-item" @click="toggle" to="/u/playlist"> Ver Playlist</RouterLink>
                </li>

                <li class="border-b border-orange">
                    <a class="text-orange hover:text-brown text-18 text-center font-semibold tracking-widest block p-3 menu-item" @click="toggle" href="https://sonymusic.com.br/terms-and-conditions/" target="_blank"> Termos de uso</a>
                </li>

                <li class="border-b border-orange">
                    <a class="text-orange hover:text-brown text-18 text-center font-semibold tracking-widest block p-3 menu-item" @click="toggle" href="https://sonymusic.com.br/politica-de-privacidade/" target="_blank"> Política de privacidade</a>
                </li>

                <li class="border-b border-orange">
                    <a class="text-orange hover:text-brown text-18 text-center font-semibold tracking-widest block p-3 menu-item" @click="toggle" href="/files/regulamento.pdf" target="_blank"> Regulamento</a>
                </li>

                <li class="border-b border-orange">
                    <RouterLink class="text-orange hover:text-brown text-18 text-center font-semibold tracking-widest block p-3 menu-item" @click="toggle" to="/u/logout"> Sair</RouterLink>
                </li>

            </ul>
        </div>
    </transition>
</template>

<script setup>
import { ref } from "vue";
const showMenu = ref(false);

const showPlaylist = ref(false);

const toggle = () => {
    showMenu.value = !showMenu.value;
};

const toggleShowPlaylist = () => {
    showMenu.value = false;
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.5s;
}
.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
}

.menu-enter-active,
.menu-leave-active {
    transition: opacity 0.5s;
}
.menu-enter,
.menu-leave-to {
    opacity: 0;
    transition: opacity 0.5s;
}
/* --- */
.menu-item.router-link-active {
    color: #000;
}
</style>
