<template>
    <div class="flex text-center flex-col justify-center pt-8">
        <h2 class="text-center text-20 font-poller uppercase mb-5">Convide seus amigos</h2>

        <p class="pb-5 text-18">Compartilhe o link da lista com os {{ $totalInvites }} amigos que você quer no seu churrasco</p>

        <div class="mb-6 px-4 py-3 border-white border-2 rounded-md bg-orange add-ellipsis">
            <span class="text-16">Link do convite:</span><br />
            <strong>{{ url }}</strong>
        </div>

        <button class="btn flex justify-center gap-2 items-center" @click="copyCode()">
            <vue-feather type="copy" size="18" />
            <span>Copiar link</span>
        </button>
    </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed } from "vue";
import { useUIStore } from "@/stores/uiStore";

const APP_URL = import.meta.env.VITE_APP_URL;

const uiStore = useUIStore();
const emit = defineEmits(["close"]);

const props = defineProps({
    listId: String,
    default: "",
});

const listId = ref(props.listId);

const url = computed(() => {
    return `${APP_URL}/i/${props.listId}`;
});

const copyCode = async () => {
    try {
        const listId = props.listId.id;
        await navigator.clipboard.writeText(`${url.value}`);
        uiStore.addToastMessages("Link copiado com sucesso!", "success");
    } catch (error) {
        uiStore.addToastMessages("Erro ao copiar o link!", "fail");
    }
    emit("close");
};
</script>

<style scoped>
.add-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
