<template>
    <div class="flex flex-col items-center space-y-2 my-2">
        <button class="btn is-outline hover:border-light-green hover:text-light-green relative w-full" @click="handleInvite('phone')">
            <vue-feather type="message-circle" class="w-6 h-6 absolute left-4" />
            <span class="mx-auto pt-1">Convidar via WhatsApp</span>
        </button>
        <button class="btn is-outline hover:border-light-green hover:text-light-green relative w-full" @click="handleInvite('email')">
            <vue-feather type="mail" class="w-6 h-6 absolute left-5" />
            <span class="mx-auto pt-1">Convidar via E-mail</span>
        </button>
    </div>
</template>

<script setup>
import { defineProps, inject } from "vue";

const props = defineProps({
    list: Object,
});

const handleInvite = (type) => {
    // console.log("InviteButtons.vue", "handleInvite", props.list.id, type);
    if (type === 'phone') {
        openWhatsAppWeb(props.list.id);
    } else {
        sendMail(props.list.id);
    }
};

const openWhatsAppWeb = inject("openWhatsAppWeb");
const sendMail = inject("sendMail");

</script>
