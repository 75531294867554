<template>
    <transition name="fade">
        <div v-if="hasLoading" class="p-5 fixed z-[100] top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-65">
            <div class="loading">
                <div class="loading__inner"></div>
            </div>
            <h2 class="font-poller text-18 p-4 uppercase">{{ textLoading }}</h2>
        </div>
    </transition>
</template>

<script setup>

import { useUIStore } from "@/stores/uiStore";
import { storeToRefs } from "pinia";

const uiStore = useUIStore();

const { hasLoading, textLoading } = storeToRefs(uiStore);

</script>

<style scoped>
    @keyframes animatedBackground {
        0% {
            background-position-x: 0%;
        }
        50% {
            background-position-x: 100%;
        }
        100% {
            background-position-x: 0%;
        }
    }

    .loading {
        @apply relative w-full max-w-[300px] h-[14px] bg-[#A15934] overflow-hidden rounded-lg;
        box-shadow: -1px -1px 20px 0px rgb(25 251 74 / 50%);
    }

    .loading__inner {
        @apply absolute top-0 w-full h-full;
        background: url(@/assets/images/bg-progress-bar.png) repeat-x;
        animation: animatedBackground 4s linear infinite;
    }
</style>
