<template>

    <Modal @close="cancel">
        <h2 class="text-center text-18 font-poller uppercase mb-4">{{ title }}</h2>
        <p class="text-center mb-8 text-16" v-html="message"/>
        <div class="relative mb-4">
            <input type="text" class="form-control peer" id="confirmationText" v-model="confirmationText" @keyup.enter="checkAndConfirm" />
            <float-label for="confirmationText" :value="confirmationText">Digite "deletar" para confirmar remoção</float-label>
        </div>
        <div class="flex justify-end space-x-2">
            <button class="btn is-outline pt-[10px] ml-4" @click="confirm" :class="{ '!bg-red-500': confirmationText === 'deletar', '!bg-red-300': confirmationText !== 'deletar' }" :disabled="confirmationText !== 'deletar'">Confirmar</button>
            <button class="btn is-outline pt-[10px]" @click="cancel">Cancelar</button>
        </div>
    </Modal>

</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onUnmounted } from "vue";
import Modal from "@/components/Modal.vue";
import FloatLabel from "@/components/forms/FloatLabel.vue";

const props = defineProps({
    title: String,
    message: String,
    isVisible: Boolean,
});

const emit = defineEmits(["confirm", "cancel"]);

const confirmationText = ref("");

function confirm() {
    if (confirmationText.value.toLowerCase() === "deletar") {
        emit("confirm");
        confirmationText.value = "";
    }
}

function cancel() {
    emit("cancel");
    confirmationText.value = "";
}

function checkAndConfirm() {
    if (confirmationText.value.toLowerCase() === "deletar") {
        confirm();
    }
}

function onEscapeKeyDown(event) {
    if (event.key === "Escape") {
        cancel();
    }
}

onMounted(() => {
    window.addEventListener("keydown", onEscapeKeyDown);
});

onUnmounted(() => {
    window.removeEventListener("keydown", onEscapeKeyDown);
});

</script>
