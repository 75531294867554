<template>

    <ul v-if="list.invites.length > 0">

        <li v-for="(invite, index) in list.invites" :key="invite.id" class="flex justify-between items-center p-2 border-b border-[#994242] last:border-b-0">
            <div>
                <div class="flex gap-2 leading-4">
                    <span>{{ index + 1 }}</span>
                    <div>
                        <span class="font-roboto text-16">{{ invite.name }}</span>
                        <br />
                        <span v-if="invite.isVerified" class="font-bebas tracking-widest text-12 uppercase text-light-green">Confirmado</span>
                        <span v-else class="font-bebas tracking-widest text-12 uppercase">Aguardando</span>
                    </div>
                </div>
            </div>

            <div class="flex items-center gap-2 text-white">

                <div v-if="invite.isVerified">
                    <vue-feather type="check" class="text-light-green" />
                </div>

                <button v-else @click="verifyInvite(invite)" aria-label="Verify">
                    <vue-feather type="check" class="text-gray-400" />
                </button>

                <button @click="showRemovalDialog(invite)" aria-label="Delete" v-if="!isComplete">
                    <vue-feather type="trash-2" />
                </button>

            </div>
        </li>
    </ul>

    <p v-else>Nenhum convidado nesta lista.</p>

    <transition name="fade">
        <RemovalConfirmationDialog v-if="isDialogVisible" :title="'Confirmar remoção'" :message="'Digite \'deletar\' para confirmar a remoção do convite.'" @confirm="confirmDeletion" @cancel="cancelDeletion" />
    </transition>

</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";
import RemovalConfirmationDialog from "@/components/RemovalConfirmationDialog.vue";

const limitInvite = import.meta.env.VITE_LIST_INVITE_LIMIT;

const props = defineProps({
    list: {
        type: Object,
        required: true,
    },
    isComplete: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(["list:invite:delete", "list:invite:verify"]);

const verifyInvite = (inviteId) => {
    emit("list:invite:verify", inviteId);
};

const deleteInvite = (inviteId) => {
    emit("list:invite:delete", inviteId);
    isDialogVisible.value = false;
};

// confirmation
const isDialogVisible = ref(false);
const inviteToRemove = ref(null);

const showRemovalDialog = (invite) => {
    inviteToRemove.value = invite;
    isDialogVisible.value = true;
};

const confirmDeletion = () => {
    if (inviteToRemove.value) {
        deleteInvite(inviteToRemove.value.id);
    }
};

const cancelDeletion = () => {
    isDialogVisible.value = false;
};

</script>
