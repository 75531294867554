<template>
    <div class="max-w-[360px] md:max-w-[590px] mx-auto flex flex-col justify-center items-center">
        <h1 class="text-center text-18 md:text-32 font-poller uppercase">
            primeiro passo: <br />
            o cadastro!
        </h1>

        <sep-image />
        <p class="text-center text-16 md:text-20 mb-6 uppercase">Preencha os campos abaixo e comece a adicionar a galera</p>
        <div class="card">
            <form @submit.prevent="saveProfile" autocomplete="off">
                <InputField id="displayName" v-model="displayName" label="Nome" icon="user" :error="formErrors?.name" />
                <InputField id="userEmail" v-model="userEmail" label="E-mail" icon="mail" :error="formErrors?.email" disabled />
                <PhoneField id="userPhone" v-model="userPhone" label="Telefone" icon="phone" :maxlength="15" :error="formErrors?.phone" />
                <InputField id="userCity" v-model="userCity" label="Cidade" icon="map-pin" :error="formErrors?.city" />
                <SelectStateField id="userState" v-model="userState" label="Estado" icon="map-pin" :error="formErrors?.state" />
                <DateField id="userBirthday" v-model="userBirthday" label="Data de nascimento" icon="calendar" :maxlength="10" :error="formErrors?.birthday" />

                <!-- Consentimento -->
                <div class="flex items-center">
                    <input type="checkbox" id="acceptTerms" v-model="acceptTerms" class="h-4 w-4 text-green-600 rounded bg-gray-800 border-gray-600 focus:ring-green-500" />
                    <label for="acceptTerms" class="ml-2 block text-sm text-white normal-case">
                        <span class="text-18">Eu concordo com os <a href="/files/regulamento.pdf" class="colo-white underline" target="_blank">termos de uso</a></span>
                    </label>
                </div>

                <div class="mb-5">
                    <span class="field-error" v-if="formErrors?.acceptTerms">{{ formErrors.acceptTerms[0] }}</span>
                </div>

                <LoadingButton type="submit" :isLoading="isLoading" :class="{ '!opacity-100': acceptTerms }">
                    <span>Avançar</span>
                    <vue-feather type="chevrons-right" class="w-6 h-6 inline-block text-orange" />
                </LoadingButton>
            </form>
        </div>
    </div>

    <menu-app v-if="userStatus === 'registered'" />
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useUIStore } from "@/stores/uiStore";
import { isValidDate, isValidPhoneNumber } from "@/utils/utils";

import FloatLabel from "@/components/forms/FloatLabel.vue";
import FrameBorder from "@/components/FrameBorder.vue";

import InputField from "@/components/forms/InputField.vue";
import SelectStateField from "@/components/forms/SelectStateField.vue";
import PhoneField from "@/components/forms/PhoneField.vue";
import DateField from "@/components/forms/DateField.vue";
import LoadingButton from "@/components/forms/LoadingButton.vue";

const router = useRouter();
const authStore = useAuthStore();

const uiStore = useUIStore();
const { addLoadingItem, removeLoadingItem, addToastMessages } = uiStore;

const displayName = ref(authStore.user.name);
const userEmail = ref(authStore.user.email);
const userPhone = ref(authStore.user.phone);
const userState = ref(authStore.user.state);
const userCity = ref(authStore.user.city);
const userBirthday = ref(authStore.user.birthday);
const userStatus = ref(authStore.user.status);
const isGuest = ref(authStore.user.status === "guest");
const acceptTerms = ref(authStore.user.status === "guest" || authStore.user.status === "registered");
const formErrors = ref({});

const isLoading = ref(false);

const saveProfile = async () => {
    if (checkForm() !== true) return;

    isLoading.value = true;
    addLoadingItem();

    authStore.user = {
        name: displayName,
        phone: userPhone,
        state: userState,
        city: userCity,
        birthday: userBirthday,
        accept_terms: acceptTerms,
    };

    try {
        await authStore.saveProfile();

        if (isGuest.value) {
            await router.push("/u/lists/create");
        } else {
            await router.push("/u/lists");
        }
    } catch (error) {
        console.log(error);

        if (error.response.data.message) {
            uiStore.addToastMessages(error.response.data.message, "fail");
        }

        if (error.message) {
            uiStore.addToastMessages(error.message, "fail");
        }

        if (error.response.data.errors) {
            formErrors.value = error.response.data.errors;
        }
    }

    uiStore.removeLoadingItem();
    isLoading.value = false;
};

// validation
const checkForm = () => {
    formErrors.value = {};

    if (!displayName.value || displayName.value.trim().length === 0) formErrors.value.name = ["O campo nome é obrigatório"];
    if (!userEmail.value || userEmail.value.trim().length === 0) formErrors.value.email = ["O campo e-mail é obrigatório"];

    if (!userPhone.value || userPhone.value.trim().length === 0) {
        formErrors.value.phone = ["O campo telefone é obrigatório"];
    } else {
        if (!isValidPhoneNumber(userPhone.value)) formErrors.value.phone = ["O número de telefone é inválido"];
    }

    if (!userState.value) formErrors.value.state = ["O campo estado é obrigatório"];

    if (!userCity.value || userCity.value.trim().length === 0) formErrors.value.city = ["O campo cidade é obrigatório"];

    if (!userBirthday.value || userBirthday.value.trim().length === 0) {
        formErrors.value.birthday = ["O campo data de nascimento é obrigatório"];
    } else {
        if (!isValidDate(userBirthday.value)) formErrors.value.birthday = ["A data de nascimento é inválida"];
    }

    if (!acceptTerms.value || acceptTerms.value !== true) formErrors.value.acceptTerms = ["Você precisa ler e concordar com os termos de uso"];

    return Object.keys(formErrors.value).length <= 0;
};
</script>
