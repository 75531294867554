<template>

    <div class="bg-brown rounded-lg shadow-md p-4 mx-auto my-4 w-full min-w-[350px] relative">

        <div v-if="isComplete" class="absolute top-[-20px] left-1/2 transform -translate-x-1/2 flex justify-center items-center">
           <div class="flex flex-row justify-center items-center bg-light-green rounded-full p-2">
               <vue-feather type="check" size="24" class="text-brown" />
           </div>
        </div>

        <div v-if="!isComplete && hasMinVerified && hasPhrase" class="absolute top-[-14px] left-1/2 w-full transform -translate-x-1/2 flex justify-center items-center">
            <div class="flex flex-row justify-center items-center bg-light-green rounded-full pr-4 pl-3">
                <vue-feather type="alert-triangle" size="18" class="text-brown" />
                <span class="text-brown uppercase pl-1">Requer confirmação</span>
            </div>
        </div>

        <div v-if="!isComplete && hasMinVerified && !hasPhrase" class="absolute top-[-14px] left-1/2 w-full transform -translate-x-1/2 flex justify-center items-center">
            <div class="flex flex-row justify-center items-center bg-light-green rounded-full pr-4 pl-3">
                <vue-feather type="alert-triangle" size="18" class="text-brown" />
                <span class="text-brown uppercase pl-1">Requer frase</span>
            </div>
        </div>

        <h2 class="font-bebas tracking-widest text-22 text-muted leading-6 pr-4 py-4 truncate">{{ list.title }}</h2>

        <div class="flex justify-between items-center mb-5">
            <!-- /contagem de participantes-->
            <InviteCounter :list="list" />

            <div v-if="!hasMinVerified"  class="flex gap-2">
                <button ref="v => singletons.push(v)" v-tippy="'Editar lista'" class="block leading-none h-[24px] hover:text-light-green" @click="handleEdit" aria-label="Editar">
                    <vue-feather type="edit" />
                </button>
                <button ref="v => singletons.push(v)" v-tippy="'Copiar link'" class="block leading-none h-[24px] hover:text-light-green" @click="openCopyCodeModal" aria-label="Copiar link">
                    <vue-feather type="copy" />
                </button>
                <button ref="v => singletons.push(v)" v-tippy="'Remover lista'" class="block leading-none h-[24px] hover:text-light-green" @click="handleDelete" aria-label="Deletar">
                    <vue-feather type="trash-2" />
                </button>
            </div>
            <div v-else>
                <div class="bg-white text-orange rounded px-2 mt-2 inline-block font-bold flex flex-row items-center justify-center">
                    <span class="uppercase">Lista Completa</span>
                </div>
            </div>

        </div>

        <div>
            <!-- ícon/botões de edição da lista -->
            <div class="flex gap-2" v-if="countList > 0">
                <button @click="toggleAccordion" class="w-full h-full flex items-center justify-center text-lg font-semibold rounded-full">
                    <vue-feather size="36" :type="showAccordion ? 'chevron-up' : 'chevron-down'" />
                </button>
            </div>
        </div>

        <!-- progresso da lista -->
        <ProgressBar class="mb-2" :count="countList" :max="parseInt(limitInvite)" />

        <transition name="fade">
            <Invites :list="list" :isComplete="isComplete" @list:invite:delete="handleDeleteInvite" v-if="showAccordion" />
        </transition>

        <InviteButtons v-if="false" class="pt-5" :list="list" />

        <!-- campo para enviar a frase da campanha -->
        <div v-if="isComplete">
            <div class="btn is-outline bg-light-green relative w-full my-2 cursor-default">
                <vue-feather type="check" class="w-6 h-6 absolute left-5" />
                <span class="uppercase font-bebas">Lista concorrendo</span>
            </div>
        </div>
        <div v-else>
            <div v-if="hasMinVerified" class="mt-4">
                <button class="btn is-outline hover:border-light-green hover:text-light-green relative w-full my-2" @click="handlePhraseSubmit">
                    <div v-if="hasPhrase" class="mx-auto">
                        <span class="mx-auto pt-1">Confirmar</span>
                    </div>
                    <div v-if="!hasPhrase" class="mx-auto">
                        <vue-feather type="alert-triangle" class="w-5 h-5 absolute left-5" />
                        <span class="mx-auto pt-1">Enviar Frase</span>
                    </div>
                </button>
            </div>
        </div>

        <div v-if="!limitReached">
            <sep-image v-if="!showAccordion" />
            <InviteButtons :list="list" />
        </div>

    </div>

</template>

<script setup>
// setup
import { ref, defineProps, defineEmits, onMounted, computed, watch, watchEffect, inject } from "vue";

// store
import { useListStore } from "@/stores/listStore";
import { useUIStore } from "@/stores/uiStore";

// components
import InviteButtons from "@/components/list/InviteButtons.vue";
import PhraseButton from "@/components/list/PhraseButton.vue";
import InviteCounter from "@/components/list/InviteCounter.vue";
import Invites from "@/components/list/Invites.vue";
import ProgressBar from "@/components/list/ProgressBar.vue";

import { useSingleton } from 'vue-tippy'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const singletons = ref([]);

useSingleton(singletons, {
    placement           : 'top',
    theme               : 'dark',
    moveTransition      : 'transform 0.2s ease-out',
});

const limitInvite = import.meta.env.VITE_LIST_INVITE_LIMIT || 10;

const { deleteInvite, isInviteLimitReached, hasVerifiedInvites } = useListStore();

const emit = defineEmits(["list-edit", "list-delete", "open-copy-code-modal", "show-playlist", "phrase-submit"]);

const props = defineProps({
    list: Object,
});

// data
const showAccordion     = ref(false);
const limitReached      = ref(false);
const hasMinVerified    = ref(false);
const showPlaylist      = ref(false);
const hasPhrase         = ref(false);
const isComplete        = ref(false);

// data stores
const uiStore = useUIStore();
const { addToastMessages } = uiStore;

async function updateStates() {

    limitReached.value = await isInviteLimitReached(props.list.id);
    hasMinVerified.value = await hasVerifiedInvites(props.list.id);

    if (props.list && props.list.phrase) {
        hasPhrase.value = props.list.phrase.trim() !== '';
    } else {
        hasPhrase.value = false;
    }

    isComplete.value = props.list && props.list.status === 'complete';


}

onMounted(updateStates);

const countList = computed(() => (props.list && props.list.invites ? props.list.invites.length : 0));

watch(() => props.list.id, updateStates);
watch(() => props.list.invites, updateStates, { deep: true });

watchEffect(async () => {
    if (props.list && props.list.invites) {
        limitReached.value = await isInviteLimitReached(props.list.id);
    }
});

const openCopyCodeModal = async () => {
    emit("open-copy-code-modal", props.list.id);
};

const toggleAccordion = () => {
    showAccordion.value = !showAccordion.value;
};

const handleDeleteInvite = (inviteId) => {
    deleteInvite(inviteId, props.list);
};

const handleEdit = () => {
    // console.log("List.vue", "Edit list", props.list.id);
    if (props.list) {
        emit("list-edit", props.list.id);
    }
};

const handlePhraseSubmit = () => {
    // console.log("List.vue", "Phrase submit", props.list.id);
    if (props.list) {
        emit("phrase-submit", props.list.id);
    }
};

const handleDelete = () => {
    // console.log("List.vue", "Delete list", props.list.id);
    if (props.list) {
        showRemovalDialog(props.list.id);
        //emit('list-delete', props.list.id);
    }
};

const toggleShowPlaylist = () => {
    showPlaylist.value = !showPlaylist.value;
    // console.log('ListItem', 'togglePlaylist', showPlaylist.value);
    emit("show-playlist", showPlaylist.value);
};

const showRemovalDialog = inject("showRemovalDialog");

</script>

<style>
    .tippy-box {
        @apply bg-white text-orange font-bold uppercase;
    }
    .tippy-box > .tippy-svg-arrow {
        fill: text-brown;
    }
    .tippy-arrow {
        @apply text-white;
    }
</style>
