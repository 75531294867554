<template>
    <img src="@/assets/svgs/404.svg" alt="Page not found" class="mx-auto mb-10" />
    <h2 class="text-center text-18 md:text-[28px] font-poller uppercase">Página não <br /> econtrada!</h2>
    <sep-image />
    <p class="text-center text-16 md:text-20 mb-6 leading-normal">
        Só falta você se cadastrar para cavar<br />
        uma vaguinha nessa resenha!
    </p>
    <button @click="back()" class="btn relative w-full max-w-[450px] mb-5">
        <span class="mx-auto pt-1">Ir para home</span>
    </button>
</template>

<script setup>
    import { useRouter } from "vue-router";
    import { notFound } from "../router";
    const router = useRouter();
    const back = () => {
        notFound.value = false;
        router.push('/');
    };
</script>
