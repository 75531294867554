const formatDate = (value) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    return value;
};

const formatPhone = (value) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");
    return value;
};

const isValidDate = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    if (cleanedValue.length !== 8) {
        return false;
    }
    const day = parseInt(cleanedValue.substring(0, 2));
    const month = parseInt(cleanedValue.substring(2, 4));
    const year = parseInt(cleanedValue.substring(4, 8));
    const isValidDate = (d, m, y) => {
        const date = new Date(y, m - 1, d);
        return (
            date.getDate() === d &&
            date.getMonth() + 1 === m &&
            date.getFullYear() === y
        );
    };
    return isValidDate(day, month, year);
};

const isValidPhoneNumber = (value) => {
    const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
    return phoneRegex.test(value);
};

export { formatDate, isValidDate, formatPhone, isValidPhoneNumber }
