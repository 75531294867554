import { ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import { useAuthStore } from "@/stores/authStore";
import { useListStore } from "@/stores/listStore";
import { useUIStore } from "@/stores/uiStore";

import Home from "@/views/Home.vue";
import Invite from "@/views/invite/Invite.vue";
import InviteRegister from "@/views/invite/InviteRegister.vue";
import Profile from "@/views/user/Profile.vue";
import Lists from "@/views/user/Lists.vue";
import Invites from "@/views/user/Invites.vue";
import Playlist from "@/views/user/Playlist.vue";
import SpotifyCallback from "@/auth/SpotifyCallback.vue";
import DeezerCallback from "@/auth/DeezerCallback.vue";
import NotFound from "@/views/NotFound.vue";
import Closed from "@/views/Closed.vue";

const routes = [
    { path: "/", name: "home", component: Home },
    { path: "/u/register/", name: "user:register", component: Profile, meta: { requiresAuth: true, allowedStatus: ["guest", "social"] } },
    { path: "/u/profile/", name: "user:profile", component: Profile, meta: { requiresAuth: true, allowedStatus: ["registered"] } },
    { path: "/u/lists/:open?", name: "user:lists", component: Lists, meta: { requiresAuth: true, allowedStatus: ["registered", "guest"] } },
    // { path: "/u/lists/", name: "user:lists", component: Lists, meta: { requiresAuth: true, allowedStatus: ["registered", "guest"] } },
    { path: "/u/invites/", name: "user:invites", component: Invites, meta: { requiresAuth: true, allowedStatus: ["registered", "guest"] } },
    { path: "/u/playlist/", name: "user:playlist", component: Playlist, meta: { requiresAuth: true, allowedStatus: ["registered", "guest"] } },
    { path: "/u/logout/", name: "user:logout", component: Home, meta: { requiresAuth: true } },

    { path: "/i/:referralCode", name: "invite", component: Invite, meta: { saveReferralCode: true } },
    { path: "/i/:referralCode/register", name: "invite:register", component: InviteRegister, meta: { requiresAuth: true, allowedStatus: ["guest", "registered", "social"] } },

    { path: "/auth/spotify/callback/", name: "auth:spotify:callback", component: SpotifyCallback },
    { path: "/auth/deezer/callback/", name: "auth:deezer:callback", component: DeezerCallback },
    { path: "/closed", name: "closed", component: Closed }
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export const notFound = ref(false);

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const listStore = useListStore();
    const referralCode = await listStore.getReferralCode(to);
    const isAuthenticated = authStore.isAuthenticated();
    const userStatus = authStore.getUserStatus();
    const isValidCode = await isValidReferralCode(referralCode, listStore);

    const promoEnded = true;

    if (promoEnded && to.path !== "/") {
        next("/");
        return;
    }

    if (to.path === "/u/logout" && to.meta.requiresAuth) {
        await authStore.logout();
        await listStore.clearReferralCode();
        localStorage.removeItem("authType");
        next("/");
        return;
    }

    if (to.params.referralCode && to.meta.saveReferralCode) {
        listStore.setReferralCode(referralCode);
    }

    if (to.params.referralCode && !isValidCode) {
        notFound.value = true;
        next(false);
        return;
    }

    if (to.meta.requiresAuth && !isAuthenticated) {
        referralCode && isValidCode ? next(`/i/${referralCode}`) : next("/");
        return;
    }

    if (to.meta.requiresAuth && !checkAuthorization(userStatus, to.meta.allowedStatus)) {
        // if (to.path === "/u/lists" && userStatus === "guest") {
        //     next("/u/register");
        //     return;
        // }

        referralCode && isValidCode ? next(`/i/${referralCode}`) : next("/");
        return;
    }

    next();
});

router.afterEach(async (to, from) => {
    const authStore = useAuthStore();
    const listStore = useListStore();

    if (notFound.value === false) {
        notFound.value = to.matched.length === 0;
    }

    if (to.path === "/") {
        await listStore.clearReferralCode();
    }
});

async function isValidReferralCode(referralCode, listStore) {
    try {
        const list = await listStore.getListByReferralCode(referralCode);
        return !!list;
    } catch (error) {
        notFound.value = true;
        console.error("Erro ao verificar o código de referência:", error);
        return false;
    }
}

function checkAuthorization(userStatus, allowedStatus) {
    return allowedStatus.includes(userStatus);
}

export default router;
