import { createApp } from "vue";
import { createPinia } from "pinia";
import { useAuthStore } from '@/stores/authStore';

import vueFeather from "vue-feather";
import VueTippy from 'vue-tippy'

import SepImage from '@/components/SepImage.vue';
import Terms from '@/components/Terms.vue';

import App from "@/App.vue";
import router from "@/router";

import "@/assets/css/index.css";

const app = createApp(App);

app.use(VueTippy, {
    defaultProps: { placement: 'top' },
});

app.use(createPinia());

app.component('sep-image', SepImage);
app.component('term-link', Terms);
app.component(vueFeather.name, vueFeather);

app.use({
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton',
    defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
    },
});

const authStore = useAuthStore();

app.config.globalProperties.$totalInvites = parseInt(import.meta.env.VITE_LIST_INVITE_LIMIT || 10);

authStore.runOnAppStart()
    .then(() => {
        app.use(router);
        app.mount("#app");
    }).catch((error) => {
        app.use(router);
        app.mount("#app");
    });
