// stores/listStore.js

import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useUIStore = defineStore("uiStore", () => {
    //
    const itemsLoading = ref([]);
    const toastMessages = ref([]);
    const textLoading = ref('Carregando ...');
    const hasLoading = computed(() => itemsLoading.value.length > 0);

    const addLoadingItem = (text) => {
        if (text) {
            textLoading.value = text;
        }
        itemsLoading.value.push(1);
    };

    const removeLoadingItem = () => {
        itemsLoading.value.pop();
        textLoading.value = 'Carregando ...'
    };

    const addToastMessages = (message, type) => {
        let id = Date.now();
        toastMessages.value.push({ type: type, message: message, id: id });
        setTimeout(() => removeToastMessages(id), 4000);
    };

    const removeToastMessages = (id) => {
        const index = toastMessages.value.findIndex((obj) => obj.id === id);
        if (index !== -1) {
            toastMessages.value.splice(index, 1);
        }
    };

    return {
        toastMessages,
        addToastMessages,
        hasLoading,
        addLoadingItem,
        removeLoadingItem,
        textLoading,
    };
});
